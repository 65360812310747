import "./Content.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import { data } from "../../data/data.js";
import Header from "../Header/Header";
import FloatingLogo from "../FloatingLogo/FloatingLogo";
import SectionHeader from "../SectionHeader/SectionHeader";
import TextBlock from "../TextBlock/TextBlock";
import RTNBlock from "../RTNBlock/RTNBlock";
import SmileyMediaBlock from "../SmileyMediaBlock/SmileyMediaBlock";
import TitleBlock from "../TitleBlock/TitleBlock";
import DifferentMediaBlock from "../DifferentMediaBlock/DifferentMediaBlock";
import SubscribeBlock from "../SubscribeBlock/SubscribeBlock";
import MediaBlock from "../MediaBlock/MediaBlock";
import Footer from "../Footer/Footer";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Landing from "../Landing/Landing";

function Content() {
  const el = useRef();
  const tl = useRef();

  const theme = Math.ceil(Math.random() * 7);

  return (
    <div ref={el} className={`content theme-${theme}`}>
      <FloatingLogo theme={theme} data={data.floatingLogo} />
      <Header theme={theme} data={data.blocks[0]} />
      <section className={`section-1 theme-${theme}`}>
        <SectionHeader theme={theme} data={data.blocks[1]} />
        <TextBlock theme={theme} data={data.blocks[2]} />
        <RTNBlock theme={theme} data={data.blocks[3]} />
      </section>
      <section className={`section-2 theme-${theme}`}>
        <SectionHeader theme={theme} data={data.blocks[4]} />
        <TextBlock theme={theme} data={data.blocks[5]} />
        <SmileyMediaBlock theme={theme} data={data.blocks[6]} />
        <TextBlock theme={theme} data={data.blocks[7]} />
        <TitleBlock theme={theme} data={data.blocks[8]} />
      </section>
      <section className={`section-3 theme-${theme}`}>
        <SectionHeader theme={theme} data={data.blocks[9]} />
        <DifferentMediaBlock theme={theme} data={data.blocks[10]} />
        <TextBlock theme={theme} data={data.blocks[11]} />
      </section>
      <section className={`section-4 theme-${theme}`}>
        <SectionHeader theme={theme} data={data.blocks[12]} />
        <TextBlock theme={theme} data={data.blocks[13]} />
      </section>
      <MediaBlock el={el} theme={theme} data={data.blocks[14]} />
      <section className={`section-5 theme-${theme}`}>
        <SectionHeader theme={theme} data={data.blocks[15]} />
        <SubscribeBlock theme={theme} data={data.blocks[16]} />
        <Footer theme={theme} data={data.blocks[17]} />
      </section>
    </div>
  );
}

export default Content;
