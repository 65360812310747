import "./RTNBlock.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { Player } from "@lottiefiles/react-lottie-player";
import { ReactComponent as RTNACSII } from "../../assets/images/rtn-ascii-2.svg";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function RTNBlock(props) {
  const el = useRef();
  const tl = useRef();
  const lottiePlayer = useRef();

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div
      ref={el}
      className={`rtn-block ${props.theme ? "theme-" + props.theme : ""} ${
        props.data.background ? "background-" + props.data.background : ""
      }`}
    >
      <div className="media">
        <RTNACSII />
        <div className={`lottie ${props.data.style}`}>
          <Player
            // animationData={animationData}
            src={"../../assets/animations/RN WORLD 04.json"}
            loop
            autoplay
            ref={lottiePlayer}

            // height={400}
            // width={400}
          />
        </div>
      </div>
      <div className="title-row">
        <div className="title-row-left">
          <h1>{props.data.title}</h1>
        </div>
        <div className="title-row-right">
          <h2>{parse(props.data.byTitle)}</h2>
          <h4>{parse(props.data.byText)}</h4>
        </div>
      </div>
    </div>
  );
}

export default RTNBlock;
