import "./MediaPlayer.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import gsap from "gsap";
import ReactPlayer from "react-player";

const MediaPlayer = ({ media, playing, theme }) => {
  const canvas = useRef();
  const imageFiles = useRef([]);
  const renderIndex = useRef(0);
  const renderInterval = useRef();
  const tl = useRef();
  const textContainer = useRef();
  const textLines = useRef([]);
  const videoPlayer = useRef();
  const videoContainer = useRef();

  const frameRate = 24;
  const paused = useRef();

  const render = () => {
    if (paused.current) return;
    console.log("render");
    if (imageFiles.current[renderIndex.current]) {
      const ctx = canvas.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      const img = imageFiles.current[renderIndex.current];
      const scale = Math.min(
        canvas.current.width / img.width,
        canvas.current.height / img.height
      );
      const x = canvas.current.width / 2 - (img.width / 2) * scale;
      const y = canvas.current.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      // TODO: add handler here if its not auto playing
      renderIndex.current =
        renderIndex.current + 1 >= imageFiles.current.length
          ? 0
          : renderIndex.current + 1;
    }
  };

  const themePrimaryColor = () => {
    if (!theme) return;
    switch (theme) {
      case 1:
        return "blue";
      case 2:
        return "orange";
      case 3:
        return "pink";
      case 4:
        return "purple";
      case 5:
        return "yellow";
      case 6:
        return "green";
      case 7:
        return "cyan";
    }
  };

  useEffect(() => {
    if (!media || !theme) return;
    console.log("init media player . load sequence etc", media, theme);

    if (media.mediaType === "image-seqeunce") {
      //preload iamges
      const themeColor = themePrimaryColor();
      console.log(
        "path",
        theme,
        themeColor,
        media.paths,
        media.paths ? media.paths[theme] : null
      );
      //let path = media.paths.orange;
      for (let i = media.startFrame; i <= media.endFrame; i++) {
        const p = new Image();
        const num = i.toString().padStart(parseInt(media.fixedDigits), "0");
        p.src = media.paths[themeColor] + num + "." + media.ext;
        imageFiles.current[i] = p;
      }
      renderInterval.current = setInterval(render, 1000 / frameRate);
    }

    if (media.mediaType === "text") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ repeat: -1 });
      tl.current.fromTo(
        textLines.current,
        { opacity: 0, y: -200 },
        { stagger: 0.2, opacity: 1, y: 0, duration: 0.4, ease: "power1.out" }
      );
      tl.current.to(textLines.current, {
        opacity: 0,
        y: 200,
        stagger: { from: "end", amount: 0.2 },
        duration: 0.4,
        delay: 0.5,
        ease: "power1.in",
      });
    }

    if (media.mediaType === "textPills") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({
        repeat: -1,
        repeatDelay: 0.0,
        repeatRefresh: true,
        invalidateOnRefresh: true,
      });
      tl.current.fromTo(
        textLines.current,
        {
          y: (i) =>
            (i * 6 * window.innerHeight) / 100 -
            (50 * window.innerHeight) / 100,
          scale: 0.0,
          opacity: 1,
        },
        {
          stagger: { from: "end", amount: 3.2 },

          rotation: "random(-5, 5)",
          duration: 0.2,
          ease: "back.out",
          scale: 1,
        },
        0
      );
      tl.current.fromTo(
        textLines.current,
        {
          y: (i) =>
            (i * 6 * window.innerHeight) / 100 -
            (50 * window.innerHeight) / 100,
        },
        {
          stagger: { from: "end", amount: 3.2 },

          y: (i) =>
            (i * 6 * window.innerHeight) / 100 +
            (150 * window.innerHeight) / 100,
          duration: 3.0,
          rotation: 0,
          ease: "sine.in",
        },
        0.1
      );
    }

    if (media.mediaType === "video") {
      // videoPlayer.play();
    }

    return () => {
      if (media.mediaType === "image-seqeunce") {
        clearInterval(renderInterval.current);
        renderInterval.current = null;
      }
    };
  }, []);

  useEffect(() => {
    console.log("playing state", playing);
    paused.current = !playing;

    if (paused.current && media.mediaType === "text") {
      tl.current.pause();
    }

    if (!paused.current && media.mediaType === "text") {
      tl.current.restart();
      tl.current.resume();
    }

    if (paused.current && media.mediaType === "textPills") {
      tl.current.pause();
    }

    if (!paused.current && media.mediaType === "textPills") {
      tl.current.restart();
      tl.current.resume();
    }

    if (!paused.current && media.mediaType === "video") {
      console.log("re positong video player");

      // videoContainer.current.left;
    }

    if ((!playing || !paused.current) && media.mediaType === "video") {
      videoPlayer.current.seekTo(0);
    }
  }, [playing]);

  return (
    <>
      {media.mediaType === "image-seqeunce" && (
        <canvas
          ref={canvas}
          className={"media-player-canvas"}
          width={1920}
          height={1080}
        ></canvas>
      )}
      {media.mediaType === "gif" && (
        <div className={`gif-container theme-${theme}`}>
          <img
            src={
              theme === 1 || theme === 2 || theme === 4
                ? "../../assets/images/gif-header-dark.svg"
                : "../../assets/images/gif-header.svg"
            }
            className="background"
            alt=""
          />
          <img className="gif" src={media.path} />
        </div>
      )}
      {media.mediaType === "video" && (
        <div
          ref={videoContainer}
          className={`video-container ${
            media.mediaStyle ? media.mediaStyle : ""
          } theme-${theme}`}
        >
          <img
            src={
              theme === 1 || theme === 2 || theme === 4
                ? "../../assets/images/video-header-dark.svg"
                : "../../assets/images/video-header.svg"
            }
            className="background"
            alt=""
          />
          <ReactPlayer
            className="react-player"
            url={media.path}
            width="100%"
            height="100%"
            controls={false}
            loop={true}
            playing={playing}
            volume={0}
            muted={true}
            ref={videoPlayer}
            playsinline={true}
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: true,
                  playsinline: true,
                  muted: true,
                },
              },
            }}
          />
        </div>
      )}
      {media.mediaType === "text" && (
        <div ref={textContainer} className="text-container">
          {media.texts.map((t, i) => {
            return (
              <h1
                key={`text-line-${i}`}
                ref={(el) => (textLines.current[i] = el)}
                className={`${t.style || ""}`}
              >
                {t.text}
              </h1>
            );
          })}
        </div>
      )}
      {media.mediaType === "textPills" && (
        <div ref={textContainer} className="text-container-pills">
          {media.texts.map((t, i) => {
            return (
              <h1
                key={`text-line-${i}`}
                ref={(el) => (textLines.current[i] = el)}
                className={`${t.style || ""}`}
              >
                {t.text}
              </h1>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MediaPlayer;
