import "./TextBlock.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import gsap from "gsap";
import { ReactComponent as RTNSVG } from "../../assets/images/rtn-logo.svg";
import { ReactComponent as RMSVG2 } from "../../assets/images/rm-logo-2.svg";
import { ReactComponent as RRTNSVG2 } from "../../assets/images/rtn-title-logo-letters.svg";
import MediaPlayer from "../MediaPlayer/MediaPlayer";
import { Player } from "@lottiefiles/react-lottie-player";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function TextBlock(props) {
  const el = useRef();
  const tl = useRef();
  const tickerBlock = useRef();
  const lottiePlayer = useRef();
  const images = [];
  images["rtn-logo"] = <RTNSVG />;
  images["rm-logo-2"] = <RMSVG2 />;
  images["rtn-logo-2"] = <RRTNSVG2 />;

  useEffect(() => {
    if (!tickerBlock.current) return;
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({});
    tl.current.to(tickerBlock.current, {
      xPercent: -105,
      duration: 5,
      ease: "none",
      repeat: -1,
    });
  }, []);

  const themePrimaryColor = () => {
    if (!props.theme) return;
    switch (props.theme) {
      case 1:
        return "green";
      case 2:
        return "yellow";
      case 3:
        return "blue";
      case 4:
        return "cyan";
      case 5:
        return "red";
      case 6:
        return "purple";
      case 7:
        return "blue";
    }
  };

  const ImageCTA = ({ media, theme }) => {
    const [playing, setPlaying] = useState(false);
    const mediaPlayerModalRef = useRef();

    // TODO: Need a way to handle Mobile version of this!!
    // Disable mouse over events on mobile and use clis
    // Look at solve I used on RM main site fo rthe Case Study cards

    const showMedia = () => {
      if (!mediaPlayerModalRef.current) return;
      setPlaying(true);
      gsap.to(mediaPlayerModalRef.current, {
        autoAlpha: 1,
        duration: 0.3,
        zIndex: 100,
      });
    };

    const hideMedia = () => {
      if (!mediaPlayerModalRef.current) return;
      setPlaying(false);
      gsap.to(mediaPlayerModalRef.current, {
        autoAlpha: 0,
        duration: 0.3,
        zIndex: 0,
      });
    };

    return (
      <>
        <span
          className={`image-cta ${props.theme ? "theme-" + props.theme : ""}  ${
            media.ctaStyle ? media.ctaStyle : ""
          }`}
          onMouseEnter={() => {
            if (media.linkType === "media") {
              showMedia(media);
            }
          }}
          onMouseLeave={() => {
            if (media.linkType === "media") {
              hideMedia();
            }
          }}
        >
          {images[media.imageCTA]}
          {media.imageCTA === "rtn-logo-2" && (
            <Player
              // animationData={animationData}
              src={"../../assets/animations/globe-animation.json"}
              loop
              autoplay
              ref={lottiePlayer}
              className="player"
              // height={400}
              // width={400}
            />
          )}
          {media.linkType === "media" && (
            <div ref={mediaPlayerModalRef} className="media-player-modal">
              <MediaPlayer media={media} playing={playing} theme={theme} />
            </div>
          )}
        </span>
      </>
    );
  };

  const TextCTA = ({ media, theme }) => {
    const [playing, setPlaying] = useState(false);
    const mediaPlayerModalRef = useRef();

    // TODO: Need a way to handle Mobile version of this!!
    // Disable mouse over events on mobile and use clis
    // Look at solve I used on RM main site fo rthe Case Study cards

    const showMedia = () => {
      if (!mediaPlayerModalRef.current) return;
      setPlaying(true);
      gsap.to(mediaPlayerModalRef.current, {
        autoAlpha: 1,
        duration: 0.3,
        zIndex: 100,
      });
    };

    const hideMedia = () => {
      if (!mediaPlayerModalRef.current) return;
      setPlaying(false);
      gsap.to(mediaPlayerModalRef.current, {
        autoAlpha: 0,
        duration: 0.3,
        zIndex: 0,
      });
    };

    return (
      <>
        <span
          className={`text-cta ${props.theme ? "theme-" + props.theme : ""}  ${
            media.ctaStyle ? media.ctaStyle : ""
          }`}
          onMouseEnter={() => {
            if (media.linkType === "media") {
              showMedia(media);
            }
          }}
          onMouseLeave={() => {
            if (media.linkType === "media") {
              hideMedia();
            }
          }}
        >
          {media.ctaText}
          {media.linkType === "media" && (
            <div ref={mediaPlayerModalRef} className="media-player-modal">
              <MediaPlayer media={media} playing={playing} theme={theme} />
            </div>
          )}
        </span>
      </>
    );
  };

  return (
    <div
      ref={el}
      className={`text-block ${props.theme ? "theme-" + props.theme : ""} ${
        props.data.background ? "background-" + props.data.background : ""
      }`}
    >
      <div className="texts">
        {props.data.text.map((t, i) => {
          return (
            // {t.media ? (
            //   <div ref={mediaPlayerModalRef} className="media-player-modal">
            //   <MediaPlayer media={media} playing={playing} theme={theme} />
            // </div>
            // ) : null}
            <>
              <>
                {t.linkType === "media" && t.mediaPlacement === "top" && (
                  <div
                    // ref={mediaPlayerModalRef}
                    className="media-player-modal-mobile"
                  >
                    <MediaPlayer media={t} playing={true} theme={props.theme} />
                  </div>
                )}
              </>
              <div
                key={`text${i}`}
                className={`text ${t.style ? t.style : ""} ${
                  props.theme ? "theme-" + props.theme : ""
                }`}
              >
                {t.smallText && (
                  <div className="small-text">
                    {parse(t.smallText, {
                      replace: (domNode) => {
                        if (
                          domNode.attribs &&
                          domNode.attribs.class === "color-cta"
                        ) {
                          return (
                            <span
                              className={`color-cta ${
                                props.theme ? "theme-" + props.theme : ""
                              }`}
                              // onClick={() => {
                              //   alert("color CTA clicked");
                              // }}
                            >
                              {" "}
                              ({themePrimaryColor()})
                            </span>
                          );
                        }
                      },
                    })}
                  </div>
                )}
                {parse(t.text, {
                  replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.class === "ticker") {
                      return (
                        <span
                          className={`${t.linkType} ${
                            props.theme ? "theme-" + props.theme : ""
                          }`}
                          onClick={() => {
                            // alert("ticker clicked");
                          }}
                        >
                          <div className="ticker-block" ref={tickerBlock}>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                            <span className={"style-1"}>{t.linkText}</span>
                            <span>{t.divider}</span>
                          </div>
                        </span>
                      );
                    }

                    if (
                      domNode.attribs &&
                      domNode.attribs.class === "image-cta"
                    ) {
                      return <ImageCTA media={t} theme={props.theme} />;
                    }

                    if (
                      domNode.attribs &&
                      domNode.attribs.class === "text-cta"
                    ) {
                      return <TextCTA media={t} theme={props.theme} />;
                    }
                  },
                })}
              </div>
              <>
                {t.linkType === "media" && t.mediaPlacement === "bottom" && (
                  <div
                    // ref={mediaPlayerModalRef}
                    className="media-player-modal-mobile"
                  >
                    <MediaPlayer media={t} playing={true} theme={props.theme} />
                  </div>
                )}
              </>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default TextBlock;
