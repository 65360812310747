import { useEffect } from "react";
import "./App.scss";
import Content from "./components/Content/Content";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function App() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);
  return <Content />;
}

export default App;
