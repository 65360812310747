import "./DifferentMediaBlock.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import gsap from "gsap";
import { ReactComponent as DifferentSVG } from "../../assets/images/different.svg";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Player } from "@lottiefiles/react-lottie-player";
import ReactPlayer from "react-player";

function DifferentMediaBlock({ data, theme }) {
  const el = useRef();
  const tl = useRef();
  const videoPlayer = useRef();
  const observer = useRef();
  const [playing, setPlaying] = useState(false);
  const lottiePlayer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          //console.log('Element is visible in screen');
          setPlaying(true);
        } else {
          //console.log('Element is not visible in screen');
          setPlaying(false);
        }
      },
      { threshold: [0] }
    );

    const ref = el.current;
    observer.current.observe(ref);

    return () => {
      observer.current.unobserve(ref);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let color = "yellow"; //2
  if (theme === 1) {
    color = "green";
  }
  if (theme === 2) {
    color = "yellow";
  }
  if (theme === 3) {
    color = "blue"; // use this if we get blue
  }
  if (theme === 4) {
    color = "cyan";
  }
  if (theme === 5) {
    color = "red"; // use this if we get red
  }
  if (theme === 6) {
    color = "purple";
  }
  if (theme === 7) {
    color = "blue"; // use this if we get blue
  }

  return (
    <div
      ref={el}
      // onMouseMove={mouseMove}
      className={`different-media-block ${theme ? "theme-" + theme : ""} ${
        data.background ? "background-" + data.background : ""
      }`}
    >
      <div className="media">
        <div className="player">
          <Player
            // animationData={animationData}
            src={"../../assets/animations/dbt.json"}
            loop
            autoplay
            ref={lottiePlayer}

            // height={400}
            // width={400}
          />
        </div>
        {/*  */}
        <div className="player-mobile">
          <Player
            // animationData={animationData}
            src={"../../assets/animations/dbt-mobile.json"}
            loop
            autoplay
            ref={lottiePlayer}

            // height={400}
            // width={400}
          />
        </div>

        <div className="video-wrapper">
          <ReactPlayer
            className="react-player"
            //url={"../../assets/videos/RotatingHead2-0000-1.mov"}
            url={[
              {
                // src: "../../assets/videos/RotatingHead2-0000-1.mov",
                src: "../../assets/videos/heads-" + color + ".mov",
                type: "video/quicktime",
              },
              {
                src: "../../assets/videos/heads-" + color + ".webm",
                type: "video/webm",
              },
            ]}
            width="100%"
            height="100%"
            controls={false}
            loop={true}
            playing={playing}
            volume={0}
            muted={true}
            playsinline={true}
            ref={videoPlayer}
          />
        </div>
      </div>
    </div>
  );
}

export default DifferentMediaBlock;
