import "./Footer.scss";
import { useEffect, useRef } from "react";
import { data } from "../../data/data.js";
import { ReactComponent as RRTNSVG2 } from "../../assets/images/rtn-logo-2.svg";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function Footer(props) {
  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div
      ref={el}
      className={`footer ${props.theme ? "theme-" + props.theme : ""}`}
    >
      <div className="divider"></div>
      <div className="footer-content">
        <div className="footer-content-left">
          <RRTNSVG2 />
          <div className="footer-titles">
            <h3 className="footer-title">{props.data.title}</h3>
            <h3 className="footer-subtitle">{props.data.subTitle}</h3>
          </div>
        </div>
        <div className="footer-content-right">
          <div className="social-links">
            {props.data.social.map((s, i) => {
              return (
                //Once links are active, update to a tag from span
                <a
                  key={`footer_${i}`}
                  className="social-link"
                  href={s.url}
                  target="_blank"
                >
                  {s.title}
                </a>
              );
            })}
          </div>
          <div className="copyright">{props.data.year}</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
