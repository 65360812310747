import "./Header.scss";
import { useEffect, useRef } from "react";
import { data } from "../../data/data.js";

// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function Header(props) {
  const el = useRef();
  const tl = useRef();

  const Ascii = ({ flip, gap }) => {
    return (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12.07 21.74"
        className={`ascii ${flip || ""} ${gap || ""}`}
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <rect y="3.99" width="2.16" height="3.1" fill="currentColor" />
          <rect y="11.46" width="2.16" height="3.1" fill="currentColor" />
          <rect
            x=".16"
            y="18.64"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect
            x="3.65"
            y="7.73"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect x="3.65" width="2.16" height="3.1" fill="currentColor" />
          <rect
            x="3.65"
            y="15.13"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect x="8.45" width="2.16" height="3.1" fill="currentColor" />
          <rect
            x="7.37"
            y="3.99"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect
            x="8.45"
            y="7.73"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect
            x="7.37"
            y="11.46"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect
            x="8.45"
            y="15.13"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          <rect
            x="7.37"
            y="18.64"
            width="2.16"
            height="3.1"
            fill="currentColor"
          />
          {/* <rect x="11.07" width="1" height="21.74" style="fill: none;" /> */}
        </g>
      </svg>
    );
  };

  useEffect(() => {
    // stuff
  }, []);

  return (
    <div
      ref={el}
      className={`header ${props.theme ? "theme-" + props.theme : ""}`}
    >
      <div className="row indent">
        {[...Array(100)].map((x, i) => (
          <Ascii
            key={i}
            flip={Math.random() > 0.8 ? "flip" : null}
            gap={i === 40 || i === 80 || Math.random() > 0.98 ? "gap" : null}
          />
        ))}
      </div>
      <div className="row outdent">
        {[...Array(80)].map((x, i) => (
          <Ascii
            key={i}
            flip={Math.random() > 0.9 ? "flip" : null}
            gap={Math.random() > 0.98 ? "gap" : null}
          />
        ))}
      </div>
    </div>
  );
}

export default Header;
