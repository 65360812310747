const data = {
  floatingLogo: {
    logos: [
      { file: "../../assets/animations/RM_LivingHold_Explore_A1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_B1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_C1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_D1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_A1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_B1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_C1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_D1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_E1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_F1_Lottie.json" },
    ],
  },
  blocks: [
    {
      type: "HeaderBlock",
      pattern: "",
    },
    {
      type: "SectionHeader",
      act: "Act",
      numeral: "I",
      number: 1,
      title: "Introduction.",
    },
    {
      type: "TextBlock",
      background: "",
      text: [
        {
          text: "The <span class='ticker'></span> is a creative community built by Raw Materials. The goal is to unite some of the best creative talent worldwide.",
          linkText: "Raw Talent Network",
          divider: "•",
          linkType: "ticker",
        },
        {
          text: "We aim to discuss, explore, and work together on non-commercial projects and on fostering the creative endeavor — a kind of super connector for creative folk.",
        },
      ],
    },
    {
      type: "RTNBlock",
      background: "primary",
      title: "Raw Talent Network",
      byTitle:
        "by <a href='https://www.therawmaterials.com/' target='_blank'>Raw Materials</a>",
      byText:
        "Minor Node of rm. <br />An Unusual Design Company.<br />A—D 2023",
    },
    {
      type: "sectionHeader",
      act: "Act",
      numeral: "II",
      number: 2,
      title: "Manifesto.",
    },
    {
      type: "TextBlock",
      background: "primary",
      text: [
        {
          text: "One of Raw Materials’ main goals is to find the best creative talent in the world and help them do the best work of their lives.",
        },
        {
          text: "The <span class='image-cta'></span> is a key part of the RM family. The focus is to bring excellent talent together and create a community around the purpose we hold so dear — To champion creativity and the unusual and to make the different.",
          imageCTA: "rtn-logo",
          ctaStyle: "margin",
          linkType: "media",
          mediaPlacement: "top",
          mediaPlacement: "top",
          mediaType: "image-seqeunce",
          paths: {
            blue: "../assets/images/rtn-seqeunce-blue/231019-RTNSpin-Blue",
            green: "../assets/images/rtn-seqeunce-green/231019-RTNSpin-Green",
            pink: "../assets/images/rtn-seqeunce-pink/231019-RTNSpin-Pink",
            cyan: "../assets/images/rtn-seqeunce-cyan/231019-RTNSpin-Cyan",
            orange:
              "../assets/images/rtn-seqeunce-orange/231019-RTNSpin-Orange",
            yellow:
              "../assets/images/rtn-seqeunce-yellow/231019-RTNSpin-Yellow",
            purple:
              "../assets/images/rtn-seqeunce-purple/231019-RTNSpin-Purple",
          },
          ext: "png",
          startFrame: 0,
          endFrame: 47,
          fixedDigits: 2,

          smallText:
            "To see more hover over elements marked in <span class='color-cta'></span>.",
          style: "margin-left-1 margin-top-1 small-text",
        },
        {
          text: "We are currently building this community, and it is — at least for now, and to help us best support it — an (invite-only) space. We are inviting folks we have met along the way and those we’ve <span class='text-cta'></span> from afar. People of different levels of experience, walks of life, and backgrounds.",
          ctaText: "(admired)",
          ctaStyle: "",
          linkType: "media",
          mediaPlacement: "top",
          mediaType: "gif",
          path: "../assets/images/admire.gif",
          style: "margin-top-1",
        },
      ],
    },
    {
      type: "MediaSmileyBlock",
      background: "primary",
    },
    {
      type: "TextBlock",
      background: "primary",
      text: [
        {
          text: "All united for their incessant <span class='text-cta'></span> for creativity — not only as a job but as a way of going through life.",
          ctaText: "(passion)",
          ctaStyle: "",
          linkType: "media",
          mediaPlacement: "bottom",
          mediaType: "text",
          texts: [
            { text: "passion", style: "outline" },
            { text: "passion", style: "outline" },
            { text: "passion", style: "outline" },
          ],
          style: "margin-left-2",
        },
        {
          text: "We will work with the community to provide activities, guidance, and opportunities both with <span class='image-cta'></span> and with other members of the group.",
          imageCTA: "rm-logo-2",
          ctaStyle: "margin tight",
          linkType: "media",
          mediaPlacement: "top",
          mediaType: "video",
          mediaStyle: "shiftRight",
          path: "https://player.vimeo.com/video/873097844?h=dd9e5f289f",
          style: "margin-top-1",
        },
        {
          text: "We want to always be in touch with the most exciting creative <span class='text-cta'></span> around the world and to help them do the best work of their lives — be that with us or with others. The more great creative work we can help exist in the world, the happier we are.",
          ctaText: "(talent)",
          ctaStyle: "",
          linkType: "media",
          mediaPlacement: "bottom",
          mediaType: "textPills",
          texts: [
            { text: "creative director", style: "green" },
            { text: "motion designer", style: "red" },
            { text: "client partner", style: "grey" },
            { text: "ux designer", style: "pink" },
            { text: "ui designer", style: "yellow" },
            { text: "project manager", style: "purple" },
            { text: "program manager", style: "cyan" },
            { text: "resource manager", style: "green" },
            { text: "senior strategist", style: "red" },
            { text: "producer", style: "grey" },
            { text: "creative developer", style: "pink" },
            { text: "lead researcher", style: "yellow" },
          ],
          style: "margin-top-1 margin-left-2",
        },
      ],
    },
    {
      type: "TitleBlock",
      background: "primary",
      titles: [
        {
          title: "The <span class='image-cta'></span> is what",
          imageCTA: "rtn-title-logo",
          ctaStyle: "margin",
          style: "margin-left-2",
        },
        { title: "we are doing", style: "margin-left-2" },
        { title: "about that", style: "margin-right-1" },
      ],
    },
    {
      type: "SectionHeader",
      act: "Act",
      numeral: "III",
      number: 3,
      title: "Different Belongs Together.",
    },
    {
      type: "MediaDifferentBlock",
      background: "secondary",
      belongsTogether: "Belongs Together",
    },
    {
      type: "TextBlock",
      background: "secondary",
      text: [
        {
          text: "We are living in the age of machines that are trained in the amalgamation of all human data — the age of the great average.",
          style: "",
        },
        {
          text: "In contrast, we each, in the beauty of our differences, offer a unique and unreplicable collection of experiences, skills, and points of view. These strengths will become an invaluable fuel to creativity in the growing effort against sameness.",
          style: "margin-left-1 margin-top-1 margin-left-1-mobile",
        },
        {
          text: "The <span class='image-cta'></span> is committed to finding exceptional creative talent who represent and embody this difference.",
          imageCTA: "rtn-logo-2",
          ctaStyle: "small",
          style: "margin-bottom-1 margin-top-1",
        },
      ],
    },
    {
      type: "SectionHeader",
      act: "Act",
      numeral: "IV",
      number: 4,
      title: "Activities.",
    },
    {
      type: "TextBlock",
      background: "primary",
      text: [
        {
          text: "The plan is to achieve that through several initiatives and activities for members to get to know each  other and collaborate.",
          style: "margin-left-2",
        },
      ],
    },
    {
      type: "MediaBlock",
      background: "primary",
      assets: [],
    },
    {
      type: "SectionHeader",
      act: "Act",
      numeral: "V",
      number: 5,
      title: "Get In Touch.",
    },
    {
      type: "SubscribeBlock",
      title:
        "If you are curious and want to learn more about the <span class='image-cta'></span>",
      title2: "Get in touch —",
      subscribeButton: "Subscribe",
      placeholderText: "hellothere@rawtalentnetwork.com",
      url: "https://handsome.us5.list-manage.com/subscribe/post?u=4d8f5a11007230e3136b358e6&id=51c2278739&f_id=009674ebf0",
      thanks: "Thank you for subscribing!",
    },
    {
      type: "FooterBlock",
      title: "Raw Talent Network",
      subTitle: "by Raw Materials",
      social: [
        { title: "Instagram", url: "https://instagram.com/the._.rtn" },
        // { title: "Twitter", url: "https://twitter.com/rawmaterialsco" },
        {
          title: "Linkedin",
          url: "https://linkedin.com/company/raw-talent-network/",
        },
      ],

      contactUrl: "mailto:hi@therawmaterials.com",
      contactText: "Contact Us",
      year: "© 2024",
    },
  ],
};

export { data };
