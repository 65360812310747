import "./SmileyMediaBlock.scss";
import { Suspense, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  AsciiRenderer,
  Environment,
  OrbitControls,
  Sphere,
  useGLTF,
} from "@react-three/drei";
import { Color, MeshPhongMaterial } from "three";
import { gsap } from "gsap";
import { EasePack } from "gsap/EasePack";
gsap.registerPlugin(EasePack);

function Model({ ...props }) {
  const ref = useRef();
  const { invalidate, camera, gl } = useThree();

  const { scene, animations } = useGLTF("./assets/models/face.glb");
  // const { actions, mixer } = useAnimations(animations, scene);

  useEffect(() => {
    scene.traverse((mesh, i) => {
      if (mesh.material && mesh.material.name === "Base") {
        mesh.material.color = new Color(props.color);
        mesh.material.envMapIntensity = 0.6;
        mesh.material.roughness = 0.2;
        mesh.material.metalness = 0.1;
        // mesh.material = new MeshPhongMaterial({
        //   color: new Color(props.color),
        // });
      }

      // mesh.frustumCulled = false;
    });
    // actions["animation_0"].timeScale = 0.5;
    // actions["animation_0"].play();
  }, []);

  useFrame((state, delta) => {
    // console.log("render");
    ref.current.rotation.y += 0.004;
    if (!props.isPaused) invalidate();
  });

  useEffect(() => {
    if (!props.isPaused) invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPaused]);
  // useCursor(hovered);

  return (
    <mesh {...props} ref={ref}>
      <primitive object={scene} />
      {/* <torusKnotGeometry args={[1, 0.2, 128, 32]} /> */}
      {/* <meshStandardMaterial color="orange" /> */}
    </mesh>
  );
}

const Effects = (props) => {
  useFrame((state, delta) => {
    //console.log(Math.random() > 0.9);
  });
  return (
    <AsciiRenderer
      isPaused={props.isPaused}
      fgColor={props.color}
      bgColor={"transparent"}
      invert={false}
      resolution={0.13}
      characters={" .▒░▓"}
    />
  );
};

function SmileyMediaBlock(props) {
  const el = useRef();
  const tl = useRef();
  const canvas = useRef();
  const effect = useRef();
  const observer = useRef();
  const [isPaused, setIsPaused] = useState(true);

  // useEffect(() => {
  //   if (!canvas.current || !effect.current) return;

  //   if (tl.current) tl.current.pause();
  //   tl.current = gsap.timeline({
  //     repeatRefresh: true,
  //     yoyo: true,
  //     //repeatDelay: "random(0,.5)",
  //     ease: "none",
  //     repeat: -1,
  //   });
  //   // tl.current.to(effect.current, {
  //   //   opacity: 0,
  //   //   duration: 5,
  //   //   ease: "rough({ template: none.out, strength: 1, points: 20, taper: none, randomize: true, clamp: false})",
  //   // });
  //   tl.current.to(canvas.current, { autoAlpha: 1 }, 0);
  //   tl.current.to(effect.current, { autoAlpha: 0 }, 0);

  //   // tl.current.to(canvas.current, { autoAlpha: 1, duration: "random(0,.1)" });
  // }, [canvas, effect]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          //console.log('Element is visible in screen');
          setIsPaused(false);
          if (tl.current) {
            tl.current.resume();
          }
        } else {
          //console.log('Element is not visible in screen');
          setIsPaused(true);
          if (tl.current) {
            tl.current.pause();
          }
        }
      },
      { threshold: [0] }
    );

    const ref = canvas.current;
    observer.current.observe(ref);

    // Listen for ASCII Effect to be ready and added to DOM
    const targetNode = el.current;
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          //console.log("A child node has been added or removed.", mutation);
          if (!effect.current) {
            effect.current = el.current.querySelector("table");
            if (tl.current) tl.current.pause();
            gsap.set(canvas.current, { opacity: 1 });
            gsap.set(effect.current, { opacity: 0 });
            tl.current = gsap.timeline({
              repeat: -1,
              onUpdate: () => {
                canvas.current.style.opacity =
                  1.0 - effect.current.style.opacity;
              },
            });
            tl.current
              .to(effect.current, {
                opacity: 0,
                duration: 0.1,
                delay: 0.5,
                ease: "power1.in",
              })
              .to(effect.current, {
                opacity: 1,
                duration: 0.15,
                delay: 0.3,
                ease: "back.inOut",
              })
              .to(effect.current, {
                opacity: 0,
                duration: 0.25,
                delay: 0.4,
                ease: "power1.in",
              })
              .to(effect.current, {
                opacity: 1,
                duration: 0.2,
                delay: 0.4,
                ease: "back.inOut",
              })
              .to(effect.current, {
                opacity: 0,
                duration: 0.35,
                delay: 0.3,
                ease: "bounce.in",
              })
              .to(effect.current, {
                opacity: 1,
                duration: 0.2,
                delay: 0.4,
                ease: "elastic.inOut",
              });
            tl.current.pause();
          }
        }
      }
    };

    const observer2 = new MutationObserver(callback);
    observer2.observe(targetNode, config);

    return () => {
      observer.current.unobserve(ref);
      observer2.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themePrimaryColor = () => {
    if (!props.theme) return;
    switch (props.theme) {
      case 1:
        return "#05FF00";
      case 2:
        return "#FF0";
      case 3:
        return "#2A38F8";
      case 4:
        return "#00F0FF";
      case 5:
        return "#FF003D";
      case 6:
        return "#5900CC";
      case 7:
        return "#2A38F8";
    }
  };

  return (
    <div
      ref={el}
      className={`smiley-media-block ${
        props.theme ? "theme-" + props.theme : ""
      } ${props.data.background ? "background-" + props.data.background : ""}`}
    >
      <Suspense fallback={null}>
        <Canvas
          camera={{ fov: 50 }}
          ref={canvas}
          frameloop="demand"

          // resize={{ scroll: false }}
        >
          {/* <color attach="background" args={["black"]} flat /> */}
          {/* 
          <directionalLight position={[10, 10, 10]} intensity={0.5} /> */}
          <ambientLight intensity={0.5} />
          <Environment preset={"sunset"} />
          <Model
            isPaused={isPaused}
            position={[0, 0, 0]}
            scale={[50, 50, 50]}
            color={themePrimaryColor()}
          />
          <Effects isPaused={isPaused} color={themePrimaryColor()} />
          <OrbitControls enableZoom={false} enablePan={false} />
        </Canvas>
      </Suspense>
    </div>
  );
}

export default SmileyMediaBlock;
