import "./SubscribeBlock.scss";
import { useEffect, useRef } from "react";
import { data } from "../../data/data.js";
import parse from "html-react-parser";
import { ReactComponent as Title2 } from "../../assets/images/subscribe-title-2.svg";
import { ReactComponent as RRTNSVG2 } from "../../assets/images/rtn-title-logo-letters.svg";
import { Player } from "@lottiefiles/react-lottie-player";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function SubscribeBlock(props) {
  const el = useRef();
  const tl = useRef();

  const subscribeForm = useRef();
  const email = useRef();
  const subscribeListItem = useRef();
  const thanks = useRef();
  const lottiePlayer = useRef();

  useEffect(() => {
    // stuff
  }, []);

  const subscribeButtonClicked = (e) => {
    e.preventDefault();
    // validate if req
    if (!email.current.value) {
      alert("Please enter an email address.");
      email.current.focus();
      return false;
    } else {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.current.value.match(validRegex)) {
        var data = new FormData(subscribeForm.current);
        data.append("mce-EMAIL", email.current.value);
        data.append("EMAIL", email.current.value);
        // console.log(data);
        fetch(props.data.url, { method: "post", mode: "no-cors", body: data })
          .then((res) => res.text())
          .then((txt) => {
            // do something when server responds
            // console.log(txt);
            thanks.current.classList.add("show");
            subscribeListItem.current.classList.add("hide");
          })
          .catch((err) => console.log(err));
        return false;
      } else {
        alert("Please enter an valid email address.");
        email.current.focus();
        return false;
      }
    }
  };

  return (
    <div
      ref={el}
      className={`subscribe-block ${
        props.theme ? "theme-" + props.theme : ""
      } ${props.data.background ? "background-" + props.data.background : ""}`}
    >
      <div className="titles">
        <h1 className="title">
          {parse(props.data.title, {
            replace: (domNode) => {
              if (domNode.attribs && domNode.attribs.class === "image-cta") {
                return (
                  <span
                    className={`image-cta ${
                      props.theme ? "theme-" + props.theme : ""
                    }`}
                  >
                    <RRTNSVG2 />
                    <Player
                      // animationData={animationData}
                      src={"../../assets/animations/globe-animation.json"}
                      loop
                      autoplay
                      ref={lottiePlayer}
                      className="player"
                      // height={400}
                      // width={400}
                    />
                  </span>
                );
              }
            },
          })}
        </h1>
        <div className="title2">
          <Title2 />
        </div>
      </div>
      <div className={`subscribe-list-item`} ref={subscribeListItem}>
        <form
          ref={subscribeForm}
          className="subscribe-form"
          onSubmit={subscribeButtonClicked}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
        >
          {/* <div className="item"> */}
          <input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            ref={email}
            className={`email`}
            placeholder={parse(props.data.placeholderText)}
          />
          {/* </div> */}
          {/* <input type="hidden" name="tags" value="4170357,4170361" readOnly /> */}
          <input type="hidden" name="tags" value="4223938" readOnly />

          <div
            style={{ position: "absolute", left: "-5000px", ariaHidden: true }}
          >
            <input
              type="text"
              name="b_4d8f5a11007230e3136b358e6_51c2278739"
              value=""
              readOnly
            />
          </div>
          <button
            id="subscribe-button"
            onClick={subscribeButtonClicked}
            className={`subscribe-button ${
              props.theme ? "theme-" + props.theme : ""
            }`}
          >
            {props.data.subscribeButton}
          </button>
        </form>
      </div>
      <div ref={thanks} className="thanks">
        {props.data.thanks}
      </div>
    </div>
  );
}

export default SubscribeBlock;
