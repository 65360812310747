import "./TitleBlock.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { ReactComponent as RTNTitleLogo } from "../../assets/images/rtn-title-logo-letters.svg";
import { Player } from "@lottiefiles/react-lottie-player";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function TitleBlock(props) {
  const el = useRef();
  const tl = useRef();
  const lottiePlayer = useRef();

  const images = [];
  images["rtn-title-logo"] = <RTNTitleLogo />;

  useEffect(() => {
    // stuff
  }, []);

  const ImageCTA = ({ media }) => {
    // const [playing, setPlaying] = useState(false);
    // const mediaPlayerModalRef = useRef();

    // TODO: Need a way to handle Mobile version of this!!
    // Disable mouse over events on mobile and use clis
    // Look at solve I used on RM main site fo rthe Case Study cards

    // const showMedia = () => {
    //   if (!mediaPlayerModalRef.current) return;
    //   setPlaying(true);
    //   gsap.to(mediaPlayerModalRef.current, {
    //     autoAlpha: 1,
    //     duration: 0.3,
    //     zIndex: 100,
    //   });
    // };

    // const hideMedia = () => {
    //   if (!mediaPlayerModalRef.current) return;
    //   setPlaying(false);
    //   gsap.to(mediaPlayerModalRef.current, {
    //     autoAlpha: 0,
    //     duration: 0.3,
    //     zIndex: 0,
    //   });
    // };

    return (
      <>
        <span
          className={`image-cta ${props.theme ? "theme-" + props.theme : ""}  ${
            media.ctaStyle ? media.ctaStyle : ""
          }`}
          // onMouseEnter={() => {
          //   if (media.linkType === "media") {
          //     showMedia(media);
          //   }
          // }}
          // onMouseLeave={() => {
          //   if (media.linkType === "media") {
          //     hideMedia();
          //   }
          // }}
        >
          {images[media.imageCTA]}
          <Player
            // animationData={animationData}
            src={"../../assets/animations/globe-animation.json"}
            loop
            autoplay
            ref={lottiePlayer}
            className="player"
            // height={400}
            // width={400}
          />
          {/* {media.linkType === "media" && (
            <div ref={mediaPlayerModalRef} className="media-player-modal">
              <MediaPlayer media={media} playing={playing} />
            </div>
          )} */}
        </span>
      </>
    );
  };

  return (
    <div
      ref={el}
      className={`title-block ${props.theme ? "theme-" + props.theme : ""} ${
        props.data.background ? "background-" + props.data.background : ""
      }`}
    >
      <div className="titles">
        {props.data.titles.map((t, i) => {
          return (
            <div
              key={`title_${i}`}
              className={`title ${t.style ? t.style : ""} ${
                props.theme ? "theme-" + props.theme : ""
              }`}
            >
              {parse(t.title, {
                replace: (domNode) => {
                  if (
                    domNode.attribs &&
                    domNode.attribs.class === "image-cta"
                  ) {
                    return <ImageCTA media={t} />;
                  }

                  // if (domNode.attribs && domNode.attribs.class === "text-cta") {
                  //   return <TextCTA media={t} />;
                  // }
                },
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TitleBlock;
