import "./FloatingLogo.scss";
import { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/rm-logo.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import { data } from "../../data/data.js";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

function FloatingLogo(props) {
  const el = useRef();
  const tl = useRef();

  const logo = useRef();
  const [logoFile, setLogoFile] = useState();

  useEffect(() => {
    // stuff
    // setLogoFile(
    //   data.floatingLogo.logos[
    //     Math.floor(Math.random() * data.floatingLogo.logos.length)
    //   ].file
    // );
  }, []);

  const logoClicked = () => {
    window.open("https://www.therawmaterials.com/");
  };

  return (
    <div
      ref={el}
      onClick={logoClicked}
      className={`floating-logo ${props.theme ? "theme-" + props.theme : ""}`}
    >
      {/* <img src={logo} alt="" /> */}
      <div className="lottie">
        <Player
          src={
            data.floatingLogo.logos[
              Math.floor(Math.random() * data.floatingLogo.logos.length)
            ].file
          }
          loop
          autoplay
        />
      </div>

      <span>By</span>
      <span>
        <a href="https://www.therawmaterials.com/" target="_blank">
          Raw
          <br />
          Materials
        </a>
      </span>
    </div>
  );
}

export default FloatingLogo;
