import "./MediaBlock.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ReactComponent as MediaCard1 } from "../../assets/images/media-card-1.svg";
import { ReactComponent as MediaCard2 } from "../../assets/images/media-card-2.svg";
import { ReactComponent as MediaCard3 } from "../../assets/images/media-card-3.svg";
import { ReactComponent as MediaCard4 } from "../../assets/images/media-card-4.svg";

import { ReactComponent as MediaCard1Mobile } from "../../assets/images/media-card-1-mobile.svg";
import { ReactComponent as MediaCard2Mobile } from "../../assets/images/media-card-2-mobile.svg";
import { ReactComponent as MediaCard3Mobile } from "../../assets/images/media-card-3-mobile.svg";
import { ReactComponent as MediaCard4Mobile } from "../../assets/images/media-card-4-mobile.svg";

function MediaBlock(props) {
  const el = useRef();
  const tl = useRef();
  const mediaBlocksContainer = useRef();
  const mediaBlocks = useRef([]);
  const mediaBlockCards = useRef([]);
  const r = useRef([]);

  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger);
    // // add scroll trigger

    // if (tl.current) tl.current.pause();
    // tl.current = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: el.current,
    //     start: "top-=10% top",
    //     end: "bottom top",
    //     scrub: 1,
    //     // onUpdate: (e) => {
    //     //   console.log(e.progress);
    //     // },
    //     invalidateOnRefresh: true,
    //   },
    // });

    // tl.current.fromTo(
    //   mediaBlocks.current[0],
    //   { y: 50, scale: 0.8 },
    //   {
    //     scale: 1,
    //     y: 0,
    //     duration: 1,
    //     ease: "none",
    //   },
    //   0
    // );

    // tl.current.to(
    //   mediaBlocks.current[0],
    //   { y: "-100%", duration: 1, ease: "none" },
    //   1.3
    // );

    // tl.current.fromTo(
    //   mediaBlocks.current[1],
    //   { y: "100vh" },
    //   { y: 0, duration: 0.8, ease: "none" },
    //   1.3
    // );

    // tl.current.to(
    //   mediaBlocks.current[1],
    //   { y: "-100%", duration: 1, ease: "none" },
    //   2.6
    // );

    // tl.current.fromTo(
    //   mediaBlocks.current[2],
    //   { y: "100vh" },
    //   { y: 0, duration: 0.8, ease: "none" },
    //   2.6
    // );

    // tl.current.to(
    //   mediaBlocks.current[2],
    //   { y: "-100%", duration: 1, ease: "none" },
    //   4
    // );

    // tl.current.fromTo(
    //   mediaBlocks.current[3],
    //   { y: "100vh" },
    //   { y: 0, duration: 0.8, ease: "none" },
    //   4
    // );

    // /* CARDS */

    // tl.current.fromTo(
    //   mediaBlockCards.current[0],
    //   { y: "100vh", x: 100 },
    //   {
    //     y: 0,
    //     x: 0,
    //     duration: 1.0,
    //     ease: "none",
    //   },
    //   0.3
    // );

    // tl.current.to(
    //   mediaBlockCards.current[0],
    //   {
    //     y: "-100vh",
    //     x: -100,
    //     duration: 1.3,
    //     ease: "none",
    //   },
    //   1.4
    // );

    // tl.current.fromTo(
    //   mediaBlockCards.current[1],
    //   { y: "100vh", x: -100 },
    //   {
    //     y: 0,
    //     x: 0,
    //     duration: 0.8,
    //     ease: "none",
    //   },
    //   1.7
    // );

    // tl.current.to(
    //   mediaBlockCards.current[1],
    //   {
    //     y: "-100vh",
    //     x: -100,
    //     duration: 0.9,
    //     ease: "none",
    //   },
    //   2.6
    // );

    // tl.current.fromTo(
    //   mediaBlockCards.current[2],
    //   { y: "100vh", x: -100 },
    //   {
    //     y: 0,
    //     x: 0,
    //     duration: 0.8,
    //     ease: "none",
    //   },
    //   3.0
    // );

    // tl.current.to(
    //   mediaBlockCards.current[2],
    //   {
    //     y: "-100vh",
    //     x: 100,
    //     duration: 0.9,
    //     ease: "none",
    //   },
    //   4.0
    // );

    // tl.current.fromTo(
    //   mediaBlockCards.current[3],
    //   { y: "100vh", x: 100 },
    //   {
    //     y: 0,
    //     x: 0,
    //     duration: 0.9,
    //     ease: "none",
    //   },
    //   4.3
    // );

    // tl.current.to(
    //   mediaBlockCards.current[3],
    //   {
    //     y: "-20vh",

    //     duration: 1,
    //     ease: "none",
    //   },
    //   5.2
    // );

    // set randoms
    r.current[0] = Math.random();
    r.current[1] = Math.random();
    r.current[2] = Math.random();
    r.current[4] = Math.random();
  }, []);

  return (
    <div
      ref={el}
      className={`media-block ${props.theme ? "theme-" + props.theme : ""} ${
        props.data.background ? "background-" + props.data.background : ""
      }`}
    >
      <div ref={mediaBlocksContainer} className="media-blocks">
        <div className="media-block media-block-1">
          <img
            className="background"
            alt=""
            src={"../../assets/images/pattern-04.svg"}
          />
          <div className="media-card-inner media-card">
            <MediaCard1 />
          </div>
          <div className="media-card-inner-mobile media-card">
            <MediaCard1Mobile />
          </div>
        </div>
        <div className="media-block media-block-2">
          <img
            className="background"
            alt=""
            src={"../../assets/images/pattern-03.svg"}
          />
          <div className="media-card-inner media-card">
            <MediaCard2 />
          </div>
          <div className="media-card-inner-mobile media-card">
            <MediaCard2Mobile />
          </div>
        </div>
        <div className="media-block media-block-3">
          <img
            className="background"
            alt=""
            src={"../../assets/images/pattern-01.svg"}
          />
          <div className="media-card-inner media-card">
            <MediaCard3 />
          </div>
          <div className="media-card-inner-mobile media-card">
            <MediaCard3Mobile />
          </div>
        </div>
        <div className="media-block media-block-4">
          <img
            className="background"
            alt=""
            src={"../../assets/images/pattern-02.svg"}
          />
          <div className="media-card-inner media-card">
            <MediaCard4 />
          </div>
          <div className="media-card-inner-mobile media-card">
            <MediaCard4Mobile />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaBlock;
